import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import classnames from "classnames/bind"

import PaddedContent from "components/paddedContent"

import styles from "styles/components/featured-articles-carousel.module.scss"

const cx = classnames.bind(styles)

const mod = (num, mod) => {
  if (num > 0) {
    return num % mod
  }
  return mod - (-num % mod)
}

const CarouselIndicators = (props) => {
  let indicators = []
  for (let i = 0; i < props.numItems; i++) {
    indicators.push(
      <button
        data-hover="expand"
        className={cx("dot", { active: props.active === i })}
        onClick={() => props.selectActive(i)}
        key={i}
      />
    )
  }
  return <div className={styles.carouselIndicators}>{indicators}</div>
}

const CarouselItem = (props) => {
  const { category, title, description, slug, heroImage } = props
  return (
    <div className={styles.flexContainer}>
      {/* EC-8936: Some case studies are not showing up in the blog category */}
      <Link to={`/${slug}/`} className={styles.textPanel}>
        {category && <p className={styles.label}>{category.text}</p>}
        <h3>{title}</h3>
        {description && <p className={styles.description}>{description}</p>}
      </Link>
      <div className={styles.imagePanel}>
        <Img fluid={heroImage?.fluid} title={heroImage?.title} alt={heroImage?.title}/>
      </div>
    </div>
  )
}

const FeaturedArticlesCarousel = ({ articles }) => {
  const [active, setActive] = useState(0)
  const interval = useRef()

  const selectActive = (i) => {
    //handleResize()
    setActive(i)
  }

  useEffect(() => {
    //handleResize()
    const next = mod(active + 1, (articles || []).length)
    interval.current = setInterval(() => setActive(next), 6000)
    return () => clearInterval(interval.current)
  }, [active, articles])

  return (
    <PaddedContent mobilePadding={8}>
      <section className={styles.container}>
        {articles &&
          articles.map((article, i) => {
            if (i === active) {
              return <CarouselItem {...article} key={i} />
            }
            return null
          })}
        {(articles || []).length > 1 && (
          <CarouselIndicators
            numItems={articles.length}
            active={active}
            selectActive={(i) => selectActive(i)}
          />
        )}
      </section>
    </PaddedContent>
  )
}

export default FeaturedArticlesCarousel
